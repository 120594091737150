<template>
  <!-- 编辑页面表单 -->
  <div class="FormCopm">
    <el-form
      :model="data"
      label-position="right"
      label-width="120px"
      size="mini"
      status-icon
      style="width: 100%"
      v-if="Object.keys(inputData).length != 0"
      ref="inputform"
    >
      <el-row :gutter="20">
        <el-col :sm="24" :md="24" :lg="18" :xl="12" :offset="0">
          <!-- 小程序标题 start @author: HDZ !-->
          <el-form-item :label="inputData.level == 3 ? '栏目标题：' : '标题：'">
            <el-input v-model="inputData.name"></el-input>
          </el-form-item>

          <!-- 一级、二级、三级 -->
          <el-form-item label="唯一标识：">
            <!-- :readonly="
                inputData.type == 0 || inputData.level == 0 ? true : false
              " -->
            <!--  inputData.level == 3? false: true && inputData.level == 4? false: true-->
            <!-- 新增的话row_id的长度是36位，小于10为编辑，且节点等级为2， -->
            <el-input
              v-model="inputData.identify"
              :disabled="inputData.row_id.toString().length < 10 && inputData.level == 2"
            ></el-input>
          </el-form-item>

          <!-- 二级栏目 -->
          <el-form-item label="区域模块：" v-if="inputData.level == 3">
            <el-select
              v-model="inputData.func"
              clearable
              placeholder="请选择"
              @change="funcSelect"
            >
              <el-option
                v-for="item in functionalModules"
                :key="item.row_id"
                :label="item.name"
                :value="item.identify"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 三级标题 -->
          <el-form-item label="路由标识：" v-if="inputData.level == 4">
            <el-input v-model="inputData.title"></el-input>
          </el-form-item>

          <!-- 三级标题 -->
          <el-form-item
            label="功能模块："
            v-if="inputData.level == 4 || inputData.level == 2"
          >
            <el-select
              v-model="inputData.func"
              clearable
              placeholder="请选择"
              @change="funcSelect"
            >
              <el-option
                v-for="item in functionalModules"
                :key="item.row_id"
                :label="item.name"
                :value="item.identify"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 二级-->
          <el-form-item label="数据资源：" v-if="$store.state.is_resource">
            <el-select
              v-model="inputData.resources"
              clearable
              placeholder="请选择"
              @change="resourcesSelect"
            >
              <el-option
                v-for="item in dataResourcess"
                :key="item.row_id"
                :label="item.name"
                :value="item.identify"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 二级-->
          <el-form-item label="页面类型：">
            <el-select
              v-model="inputData.package_type"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in packageTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 一级、二级、三级 -->
          <el-form-item :label="inputData.level == 3 ? '栏目风格：' : '风格：'">
            <!-- <el-select v-model="inputData.style" clearable placeholder="请选择" @change="styleChange" ref="select">
              <el-option v-for="item in styles" :key="item.identify" :label="item.name" :value="item.identify">
              </el-option>
            </el-select> -->
            <StyleSelector
              :styleName.sync="inputData.style"
              :styles="styles"
              source="styles"
            ></StyleSelector>
          </el-form-item>

          <!-- 一级 -->
          <el-row>
            <el-col :span="12">
              <el-form-item label="图标一：" v-if="inputData.level == 2">
                <!-- 图片上传 -->
                <image-upload
                  :imgsrc="inputData.icon"
                  filedname="icon"
                ></image-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图标二：" v-if="inputData.level == 2">
                <!-- 图片上传 -->
                <image-upload
                  :imgsrc="inputData.select_icon"
                  filedname="select_icon"
                ></image-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 三级 -->
          <el-form-item
            label="单图："
            v-if="
              inputData.is_show_img ||
              inputData.level == 2 ||
              inputData.level == 3 ||
              inputData.level == 4
            "
          >
            <!-- 图片上传 -->
            <image-upload
              :imgsrc="inputData.image"
              filedname="image"
            ></image-upload>
          </el-form-item>

          <!-- 三级 -->
          <el-form-item
            label="多图："
            v-if="
              inputData.is_show_img ||
              inputData.level == 2 ||
              inputData.level == 3 ||
              inputData.level == 4
            "
          >
            <ImagesLinkJump :imgsrc="inputData.images"></ImagesLinkJump>
          </el-form-item>

          <!-- 添加链接 -->
          <el-form-item
            label="跳转链接："
            v-if="
              inputData.level == 2 ||
              inputData.level == 3 ||
              inputData.level == 4
            "
          >
            <!-- <el-select v-model="inputData.link" clearable placeholder="请选择" @change="linkChange">
              <el-option v-for="item in linkResourcess" :key="item.url" :label="item.name" :value="item.url">
              </el-option>
            </el-select> -->

            <!-- 这里不用v-model因为他的值返回为一个数组格式，所以在事件里进行修改值 -->
            <el-cascader
              clearable
              :value="inputData.link"
              :options="linkResourcess"
              :show-all-levels="false"
              :props="{ label: 'name', value: 'url' }"
              placeholder="请选择"
              @change="linkChange"
            >
            </el-cascader>
          </el-form-item>

          <!-- 文件上传 -->
          <el-form-item v-if="inputData.identify == 'giude'" label="媒体文件：">
            <FileUpload filedname="media" :filesrc="inputData.media" />
          </el-form-item>

          <!-- 富文本 -->
          <el-form-item label="富文本：">
            <kind-editor
              :id="'kin' + inputData.row_id"
              fiele-name="content"
              :content="inputData.content"
              @kindeditorChange="kindeditorChange"
              width="100%"
            ></kind-editor>
          </el-form-item>
          <!-- 文本框 -->
          <el-form-item label="参数配置：">
            <el-input v-model="inputData.linklist" clearable></el-input>
          </el-form-item>
          <!-- 文本框 -->
          <el-form-item label="自定义样式：">
            <el-input v-model="inputData.params"></el-input>
          </el-form-item>
          <!-- 一级，二级，三级 -->
          <el-form-item label="是否显示：">
            <el-switch v-model="inputData.is_show"></el-switch>
          </el-form-item>
          <el-form-item label="是否引导页：" v-if="inputData.level == 2">
            <el-switch v-model="inputData.is_guide"></el-switch>
          </el-form-item>
          <el-form-item label="隐藏菜单" v-if="inputData.level == 2">
            <el-switch v-model="inputData.hide_menu"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="clickSave">保存</el-button>
      </el-form-item>
    </el-form>
    <div class="no-data" v-else>暂无数据</div>
  </div>
</template>


<script>
import ImageUpload from "@views/modelmanage/childecomponent/ImageUpload";
// import ImagesUpload from "@views/modelmanage/childecomponent/ImagesUpload";
import StyleSelector from "@components/StyleSelector/index";

import ImagesLinkJump from "@components/ImagesLinkJump/index";
import FileUpload from "@views/modelmanage/childecomponent/FileUpload";
import KindEditor from "@components/common/editor/EditTest";
import { mapState } from "vuex";
import { json } from "body-parser";
export default {
  name: "FormCopm",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    inputData: {
      type: Object,
      default() {
        return {
          identify: "",
        };
      },
    },
  },
  data() {
    return {
      moduleType: "",
      dataResource: {},
      newFormData: {}, // 保存所有的form数据
      // styles: [{ name: "默认", identify: "basicStyle" }], // 栏目风格
      styles: [], // 栏目风格
      moduleTypes: [
        // 模块类型
        { label: "功能模块", value: "1" },
        { label: "数据模块", value: "2" },
        { label: "列表模块", value: "3" },
      ],
      functionalModules: [], // 功能模块
      dataResourcess: [], // 数据资源
      fieldMap: {},
      is_edit: true,
      formc: null,
      imageUrl: [],
      storeImg: null,
      newData: null,
      linkResourcess: [
        {
          name: "外部链接",
          url: "外部链接",
          children: [],
        },
        {
          name: "功能模块",
          url: "功能模块",
          children: [],
        },
      ], //链接资源
      basicStyle: {
        name: "basicStyle",
        url: '',
      },
      media: "",
      // richTextWidth:'640'
      // is_resources: false, // 是否关联数据资源
      // rules: {
      //   identify: [
      //     { required: true, message: "请输入唯一标识！", trigger: "blur" },
      //     {
      //       min: 3,
      //       max: 20,
      //       message: "长度在 3 到 20 个字符(中文为2个字符)",
      //       trigger: "blur",
      //     },
      //   ],
      // },
      packageTypeOptions:[{
        label:'默认',
        value:0
      },{
        label:'主包',
        value:1
      },{
        label:'分包',
        value:2
      }]
    };
  },
  components: {
    ImageUpload,
    ImagesLinkJump,
    KindEditor,
    FileUpload,
    StyleSelector,
  },
  computed: {
    ...mapState({
      windowWidth: (state) => state.windowWidth,
    }),
  },

  created() {
    //多图
    this.$bus.$off("imgsUploaded", this.imgsUploaded);
    this.$bus.$off("imgsRemoved", this.imgsRemoved);

    //单图
    this.$bus.$off("imgUploaded", this.imgUploaded);
    this.$bus.$off("imgRemoved", this.imgRemoved);
    // console.log('这里是formCopm组件',this.data)
    console.log("functionalModules", this.functionalModules);
  },
  beforeDestroy() {
    //多图
    this.$bus.$off("imgsUploaded", this.imgsUploaded);
    this.$bus.$off("imgsRemoved", this.imgsRemoved);

    //单图
    this.$bus.$off("imgUploaded", this.imgUploaded);
    this.$bus.$off("imgRemoved", this.imgRemoved);
  },
  mounted() {
    //单图
    this.$bus.$on("imgUploaded", this.imgUploaded);
    this.$bus.$on("imgRemoved", this.imgRemoved);

    //多图
    this.$bus.$on("imgsUploaded", this.imgsUploaded);
    this.$bus.$on("imgsRemoved", this.imgsRemoved);

    // console.log(typeof this.inputData.images,(this.inputData.images).constructor,'-------------');
    // console.log("inputData", this.inputData);

    // 查询数据资源
    let params = {
      // data: { is_check: 1 },
      directory_code: "amt_resources",
    };
    this.queryDataOfResource(params);
    // 查询模块资源
    let params1 = {
      // data: { is_check: 1 },
      directory_code: "amt_module",
    };
    this.queryDataOfFuncModules(params1);

    //查询链接资源
    let params2 = {
      data: { type: 0 },
      directory_code: "amt_linkconfig",
    };

    this.queryDataOfLinkResourcess(params2);

    // 查询模块资源对应的风格
    if (this.inputData.func != "") {
      this.queryStyleDataofModule(this.inputData.func);
    }
  },
  methods: {
    // 处理风格选择框事件
    styleChange(e) {},
    // 处理类型选择框
    typeSelect(e) {},

    // 处理模块选择
    funcSelect(e) {
      console.log(e, this.inputData.func, 89);
      this.functionalModules.forEach((item) => {
        if (item.identify == e) {
          if (item.is_resources == "1") {
            // 向vuex提交
            this.$store.commit("setIsResource", true);
          } else {
            this.$store.commit("setIsResource", false);
          }

          if (this.$store.state.moduleState) {
            this.$set(this.inputData, "image", this.inputData.image);
          } else {
            //功能模块资源关联
            if (!this.inputData.image) {
              this.$set(this.inputData, "image", item.logo);
            } else if (this.inputData.image) {
              this.$set(this.inputData, "image", item.logo);
            } else {
              this.$set(this.inputData, "image", "");
            }
          }
        }
      });

      // if(e == ""){
      //   this.$set(this.inputData,"image","")
      //   }
      // 每次选中其他的模块时，都把style设置为该模块的第一项

      // this.queryStyleDataofModule(e);
      //this.inputData.style = this.styles.length>1 ? this.styles[1].identify : "basicStyle"
    },
    // 处理资源选择
    resourcesSelect(e) {
      // console.log("资源选择：", e);
    },

    //清空图片
    // clearInput(){
    //   this.$set(this.inputData,"image","")
    // },

    //处理链接资源
    linkChange(e) {
      if (e.length == 0) {
        this.inputData.link = "";
      } else {
        this.inputData.link = e[1];
      }
      console.log(e);
    },

    //处理上传前的表单  int类型 为空都写为0 删除create_time
    clearSubmitData(fromData) {
      delete fromData.create_time;
      //删除空字段
      Object.keys(fromData).map((item) => {
        // let isint = false;
        // if (this.fieldMap.hasOwnProperty(item)) {
        //   isint = this.fieldMap[item].indexOf("int") != -1;
        // }
        if (!fromData[item] && item != "link" && item != "params"&& item != "linklist") {
          delete fromData[item];
        }
        return true;
      });
      return fromData;
    },
    // 点击保存提交数据
    clickSave() {
      // console.log(this.inputData.is_show)
      this.formc = JSON.parse(
        JSON.stringify(this.clearSubmitData(this.inputData))
      );
      // console.log(this.formc,'this.formc');
      this.formc.is_show = this.formc.is_show == true ? "1" : "0";
      this.formc.is_guide = this.formc.is_guide == true ? "1" : "0";
      this.formc.hide_menu = this.formc.hide_menu == true ? "1" : "0";
      // 若选择不关联数据的项，就清空resource字段
      if (!this.$store.state.is_resource) {
        this.formc.resources = "";
      }

      this.formc.image = this.formc.image ? this.formc.image : "";
      this.formc.media = this.formc.media ? this.formc.media : "";

      this.formc.images = this.formc.images
        ? JSON.stringify(this.formc.images)
        : "";
      this.formc.content = this.formc.content ? this.formc.content : "";
      // 判断图标是否添加或者删除，若删除则重新添加一个空字段
      this.formc.icon = this.formc.icon ? this.formc.icon : "";
      this.formc.select_icon = this.formc.select_icon
        ? this.formc.select_icon
        : "";
      // 判断是否要删除
      this.formc.func = this.formc.func ? this.formc.func : "";
      this.formc.resources = this.formc.resources ? this.formc.resources : "";
      console.log(this.formc);
      // 定义要请求的参数
      let paramsForm = {
        data: this.formc,
        directory_code: this.$store.state.currentNav.index,
      };

      console.log("点击保存后", this.inputData);
      // 根据row_id的长度来判断是新添加的还是已经添加上要修改的
      // ps:因为刚添加的row_id是随机生成的，字符长
      if (this.inputData.row_id.toString().length < 10) {
        let params = {
          data: { identify: this.formc.identify },
          directory_code: this.$store.state.currentNav.index,
        };

        // 在进行编辑时要判断identify的唯一性
        this.$Request({
          method: "get",
          url: this.$Api.queryDataOfResource,
          params,
        })
          .then((res) => {
            console.log("标识查询", res);
            // 此处有个小问题，根据identify查询时，若数据库有一条数据的identify为
            // newAdd,若以ne查询，仍然会返回一条identify为newAdd的数据
            this.modifyDataOfResource(paramsForm);

            // if (res.data && res.data.length == 1) {
            //   // 若条数为1，再进行id的判断
            //   if (res.data[0].row_id == this.formc.row_id) {
            //     // id相等，则为正常修改
            //     // console.log("本身");
            //     this.modifyDataOfResource(paramsForm);
            //   } else {
            //     // 若id不相等，再比较二者的identify，若不相等，则可以修改
            //     if (res.data[0].identify == this.formc.identify) {

            //       this.modifyDataOfResource(paramsForm);
            //     } else {
            //       // console.log("标识不唯一");
            //       this.$message({
            //         type: "error",
            //         message: "标识已存在！请输入新的标识！",
            //       });
            //       console.log('我被打印了1')
            //       return false;
            //     }
            //   }
            // }
            //  else if (res.data && res.data.length == 0) {
            //   // 若条数为0，必然未重，可修改
            //   // console.log("唯一");
            //   this.modifyDataOfResource(paramsForm);
            //   //this.$store.commit("setIsIdentifyOnly", true);
            // }
            // else {
            //   // 若条数大于1，需要进行遍历判断identify
            //   let no_repeat_num = 0;
            //   res.data.forEach((item) => {
            //     // 若不相等，计数加一
            //     if (item.identify != this.formc.identify) {
            //       no_repeat_num++;
            //     } else {
            //       // 若相等,判断是否为自己,以row_id判断，为自己，计数加一
            //       if (item.row_id == this.formc.row_id) {
            //         no_repeat_num++;
            //       } else {
            //         this.$message({
            //           type: "error",
            //           message: "标识已存在！请输入新的标识！",
            //         });
            //         console.log('我被打印了2')
            //         return false;
            //       }
            //     }
            //   });
            //   // 修改时，若计数总数等于返回的数组的长度，说明标识都类似。可以修改
            //   if (no_repeat_num != res.data.length) {
            //     // console.log(`有${no_repeat_num}个类似的标识`);
            //     this.modifyDataOfResource(paramsForm);
            //   } else {
            //     this.$message({
            //       type: "error",
            //       message: "标识已存在！请输入新的标识！",
            //     });
            //     console.log('我被打印了3')
            //     return false;
            //   }
            // }
          })
          .catch((err) => {});
      } else {
        let params = {
          data: { identify: this.formc.identify },
          directory_code: this.$store.state.currentNav.index,
        };
        this.$Request({
          method: "get",
          url: this.$Api.queryDataOfResource,
          params,
        })
          .then((res) => {
            // console.log("标识查询", res);
            if (res.data && res.data.length != 0) {
              let no_repeat_num = 0;
              res.data.forEach((item) => {
                if (item.identify == this.formc.identify) {
                  no_repeat_num++;
                } else {
                  this.$message({
                    type: "error",
                    message: "标识已存在！请输入新的标识！",
                  });
                  return false;
                }
              });
              if (no_repeat_num != res.data.length) {
                // console.log(`有${no_repeat_num}个类似的标识`);
                delete this.formc.row_id;
                // console.log(this.formc);
                this.addDataOfResource(paramsForm);
              } else {
                this.$message({
                  type: "error",
                  message: "标识已存在！请输入新的标识！",
                });
                return false;
              }
            } else {
              // console.log("唯一");

              delete this.formc.row_id;
              // console.log(this.formc);
              this.addDataOfResource(paramsForm);
              //this.$store.commit("setIsIdentifyOnly", true);
            }
          })
          .catch((err) => {});
      }

      //console.log(JSON.parse(JSON.stringify(this.inputData)));
    },
    // 添加数据
    addDataOfResource(params) {
      this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        // headers: {'Content-Type': 'application/json'},
        data: params,
      })
        .then((res) => {
          if (res.errCode == 0) {
            this.$message({
              type: "success",
              message: "添加成功！",
            });
            let params = {
              data: { identify: this.formc.identify },
              directory_code: this.$store.state.currentNav.index,
            };
            this.$Request({
              method: "get",
              url: this.$Api.queryDataOfResource,
              params,
            })
              .then((res) => {
                // console.log("新添加的节点：", res);
                this.$set(this.inputData, "row_id", res.data[0].row_id);
                //console.log("id", this.formc.row_id);
                this.$bus.$emit("saveSuccess", res.data[0].row_id);
              })
              .catch((err) => {});
          }
          // console.log(res);
        })
        .catch((err) => {});
    },
    // 修改数据
    modifyDataOfResource(params) {
      this.$Request({
        method: "post",
        url: this.$Api.modifyDataOfResource,
        // headers: {'Content-Type': 'application/json'},
        data: params,
      })
        .then((res) => {
          if (res.errCode == 0) {
            this.$message({
              type: "success",
              message: "保存成功！",
            });
            // console.log("id", this.formc.row_id);
            this.$bus.$emit("saveSuccess", this.formc.row_id);

            //document.querySelector(".is-current").firstChild.click();
          }
          //console.log("success",res);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 查询数据资源
    queryDataOfResource(params) {
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          // console.log("资源查询：", res);
          if (res.errcode == 0) {
            this.dataResourcess = res.data;
          }
        })
        .catch((err) => {});
    },

    // 查询模块资源
    queryDataOfFuncModules(params, func) {
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          console.log("资源查询：", res);
          if (res.errcode == 0) {
            // 根据inputData.level来判断二级栏目的数据资源和三级栏目的功能模块
            if (this.inputData.level == 3) {
              // console.log('三级模块',this.functionalModules)
              this.functionalModules = res.data.filter(
                (item) => item.type == "0"
              );
            } else if (this.inputData.level == 4 || this.inputData.level == 2) {
              // console.log('二级模块',this.functionalModules)
              this.functionalModules = res.data.filter(
                (item) => item.type == "1"
              );
            }
          }
          this.queryStyleDataofModule(func);
        })
        .catch((err) => {});
    },

    //查询链接资源
    async queryDataOfLinkResourcess(params) {
      let res1 = await this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      });
      this.linkResourcess[0].children = res1.data;
      let res2 = await this.$Request({
        method: "get",
        url: this.$Api.queryLinkMould,
        params,
      });
      this.linkResourcess[1].children = res2.data.data.list;
      // .then((res) => {
      //   console.log("资源查询：", res);
      //   if (res.errcode == 0) {
      //     // 根据inputData.level来判断二级栏目的数据资源和三级栏目的功能模块
      //     // if (this.inputData.level == 2) {
      //     //   // console.log('三级模块',this.linkResourcess)
      //     //   this.linkResourcess = res.data

      //     // } else if (this.inputData.level == 3) {
      //     //   // console.log('二级模块',this.linkResourcess)
      //     //   this.linkResourcess = res.data
      //     // }
      //     this.linkResourcess = res.data
      //   }
      // })
      // .catch((err) => { });
    },

    //多图上传
    imgsUploaded(res) {
      // res:image的url,name则为'image'
      console.log(this.storeImg, "this.storeImg");
      this.storeImg.push(res);
      this.$set(this.inputData, "images", this.storeImg);
    },

    //多图删除
    imgsRemoved(items, index) {
      this.inputData.images.splice(index, 1);
    },

    //单图上传
    imgUploaded(url, name) {
      console.log(name);
      this.$set(this.inputData, name, url.path);
    },

    //单图删除
    imgRemoved(name) {
      this.$set(this.inputData, name, "");
    },

    // 查询模块资源的row_id
    // 查询模块资源对应的风格
    queryStyleDataofModule(func) {
      // 查询之前先把当前的风格清空为默认
      // this.styles.splice(1, this.styles.length - 1);

      let module_id = null;
      this.functionalModules.forEach((item) => {
        if (item.identify == func) {
          module_id = item.row_id;
        }
      });
      // console.log("module_id", module_id);
      let params = {
        data: { module_id: module_id, searchmodel: "exact" },
        directory_code: "amt_styleconfig",
      };
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          console.log("模块资源对应的风格查询：", res);
          if (res.errcode == 0) {
            // 跟默认的风格合并
            // this.styles = [
            //   ...this.styles,
            //   ...res.data.sort(this.compare("row_id")),
            // ];
            if (res.data.length) {
              // 对数据进行排序以及处理
              let newArr = res.data.sort(this.compare("row_id")).map((item) => {
                return {
                  url: item.image,
                  name: item.identify,
                };
              });
              // 判断有没有默认样式
              let index = newArr.findIndex((item) => item.name == "basicStyle");
              if (index == -1) {
                this.styles = [this.basicStyle, ...newArr];
              }else{
                this.styles = newArr
              }
            } else {
              this.styles = [this.basicStyle];
            }
          }else{
            this.styles = [this.basicStyle];
          }
        })
        .catch((err) => {});
    },
    // 排序
    compare(prop) {
      return function (obj1, obj2) {
        var val1 = obj1[prop];
        var val2 = obj2[prop];
        if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
          val1 = Number(val1);
          val2 = Number(val2);
        }
        if (val1 < val2) {
          return -1;
        } else if (val1 > val2) {
          return 1;
        } else {
          return 0;
        }
      };
    },

    kindeditorChange(data, name) {
      console.log(data, name);
      this.inputData.content = data;
    },
  },
  watch: {
    inputData: {
      deep: true,
      handler(e, old) {
        this.storeImg = this.inputData.images
          ? JSON.parse(JSON.stringify(this.inputData.images))
          : [];
        // 查询模块资源
        let params1 = {
          // data: { is_check: 1 },
          directory_code: "amt_module",
        };
        this.queryDataOfFuncModules(params1, e.func);
      },
    },
    // windowWidth:{
    //   deep:true,
    //   handler(nv){
    //     if(nv<=1280){
    //       this.richTextWidth="600"
    //     }
    //   }
    // }
  },
};
</script>

<style lang='scss' scoped>
.FormCopm {
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  padding: 15px;
}

.no-data {
  font-size: 14px;
  color: #909399;
  text-align: center;
}

@media (max-width: 1280px) {
  .el-form-item--mini .el-form-item__label {
    font-size: 12px;
  }
}

.el-form-item {
  margin-bottom: 14px !important;
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 3px;
  //padding-left: 10px;
}

//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 3px;
}

.imgges {
  width: 178px;
  height: 178px;
  float: left;
}
.el-cascader{
  width: 100%;
}
</style>